import { MantineProvider } from '@mantine/core'
import type { PropsWithChildren } from 'react'

export const AppTheme = ({ children }: PropsWithChildren<unknown>): JSX.Element => (
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    withCSSVariables
    theme={{
      fontFamily: 'Raleway, Arial, Helvetica, sans-serif',
      fontFamilyMonospace: 'Monaco, Courier, monospace',
      headings: {
        fontFamily: 'Mitr, sans-serif'
      }
    }}
  >
    {children}
  </MantineProvider>
)
