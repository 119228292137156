import type { TypeOf } from 'zod'
import { object, string, nativeEnum } from 'zod'
import { timestamp } from './codecs/timestamp-codec'
import { longtext } from './codecs/long-text'

export enum Sex {
  male = 'male',
  female = 'female'
}

export enum Size {
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
  giant = 'giant'
}

export const Profile = object({
  name: string()
    .min(2, { message: 'Nimi pitää olla vähintään 2 merkkiä pitkä' })
    .max(30, 'Nimi pitää olle enintään 200 merkkiä pitkä'),
  breed: string().max(50, 'Rotu pitää olle enintään 200 merkkiä pitkä').optional(),
  sex: nativeEnum(Sex),
  birthdate: timestamp().optional().nullable(),
  size: nativeEnum(Size),
  description: longtext(),
  stripeId: string().optional()
}).describe('Profile')

export type Profile = TypeOf<typeof Profile> & { id: string }
