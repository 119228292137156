import type { Position } from '../models/position'
import { pick, prop, pipe } from 'ramda'

export const getCurrentLocation = (enableHighAccuracy = false): Promise<Position> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      pipe(prop('coords'), pick(['longitude', 'latitude']), resolve),
      reject,
      {
        maximumAge: 60 * 1000,
        enableHighAccuracy
      }
    )
  })
