import type { StorageReference, UploadMetadata } from 'firebase/storage'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import pMemoize from 'p-memoize'

import { Maybe, Nothing } from 'purify-ts'
import type { User } from '../models/user'
import { imagePromise } from '../utils/image-promise'
import { storage } from './firebase'
import ExpiryMap from 'expiry-map'
import { resize } from './functions'

const cache = new ExpiryMap(30 * 60 * 1000)

export const profileRef = ({ id }: User): StorageReference => ref(storage, `${id}/profile_400.jpg`)
export const galleryRef = (userId: string, pictureId: string): StorageReference =>
  ref(storage, `${userId}/gallery_${pictureId}_400.jpg`)

export const avatarImage = pMemoize(
  (userId: string): Promise<Maybe<string>> => {
    const doc = ref(storage, `${userId}/profile_100.jpg`)
    return getDownloadURL(doc)
      .then(imagePromise)
      .then(Maybe.of)
      .catch(() => Nothing)
  },
  { cache }
)

export const uploadFile =
  (folder: string, prefix: string, sizes: number[]) =>
  async (file: File): Promise<void> => {
    const metadata: UploadMetadata = { contentType: file.type }
    const fileName = `upload_${Date.now()}.jpg`
    const uploadRef = ref(storage, `${folder}/${fileName}`)
    await uploadBytes(uploadRef, file, metadata)
    await resize({ fileName, prefix, sizes })
  }
