import type { TypeOf } from 'zod'
import { number, object, string } from 'zod'

export const Picture = object({
  owner: string(),
  aspectRatio: number(),
  dimension: object({
    width: number(),
    height: number()
  })
}).describe('Picture')

export type Picture = TypeOf<typeof Picture> & { id: string }

export type UIPicture = Picture & { url: string }
