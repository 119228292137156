import type { ReactNode } from 'react'
import { isNotEmpty } from '../../utils/is-not-empty'
import { mapIndexed } from 'ramda-adjunct'

// eslint-disable-next-line functional/no-mixed-types
type OwnProps<T> = {
  readonly list: readonly T[]
  children: (el: T, index: number) => ReactNode
}

export const NonEmptyList = <T,>({
  list,
  children: renderFunction
}: OwnProps<T>): JSX.Element | null => {
  if (!isNotEmpty(list)) {
    return null
  }
  return <>{mapIndexed(renderFunction, list)}</>
}
