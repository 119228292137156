import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import type { Profile } from './profile'
import { timestamp } from './codecs/timestamp-codec'
import type { Maybe } from 'purify-ts'

export const Participant = object({
  checkinId: string(),
  userId: string(),
  joinedAt: timestamp()
}).describe('Participant')

export type Participant = TypeOf<typeof Participant>

export type UIParticipant = Participant & {
  image: Maybe<string>
  dog: Maybe<Profile>
}
