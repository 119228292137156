import { createStore } from 'effector'
import { nthArg } from 'ramda'
import type { Maybe } from 'purify-ts'
import { Nothing } from 'purify-ts'

import type { Profile } from '../models/profile'
import { loadProfileFx } from '../events/load-profile'
import { saveProfileFx } from '../events/save-profile'
import { loadProfileImageFx } from '../events/load-profile-image'

export const $profilePicture = createStore<Maybe<string>>(Nothing).on(
  loadProfileImageFx.doneData,
  nthArg(1)
)

export const $profile = createStore<Maybe<Profile>>(Nothing)
  .on(loadProfileFx.doneData, nthArg(1))
  .on(saveProfileFx.doneData, nthArg(1))
