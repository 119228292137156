/* eslint-disable @typescript-eslint/no-explicit-any */
import { applySpec, converge, identity, mergeLeft } from 'ramda'
import type { AnyFn } from '../types/generic'

type ResolvedSpec<S> = {
  [K in keyof S]: S[K] extends AnyFn ? ReturnType<S[K]> : S[K]
}

export const addSpec: <O extends Record<string, any>, S = Record<string, AnyFn>>(
  spec: S
) => (o: O) => O & ResolvedSpec<S> = (spec): any =>
  converge(mergeLeft as AnyFn, [applySpec(spec), identity])
