import { createEffect } from 'effector'
import { geohashForLocation } from 'geofire-common'
import type { Maybe } from 'purify-ts'
import { MaybeAsync } from 'purify-ts'
import { createFriendRequest } from '../services/firestore'
import { getCurrentLocation } from '../utils/current-location'
import { subscribeFriendRequestEv } from './events'
import type { User } from '../models/user'

export const sendFriendRequestFx = createEffect(async (user: Maybe<User>) => {
  const location = await getCurrentLocation(false)
  await MaybeAsync.liftMaybe(user)
    .map(user => {
      subscribeFriendRequestEv({ location, user })
      return createFriendRequest(user, {
        ...location,
        geohash: geohashForLocation([location.latitude, location.longitude], 9),
        initiator: user.id,
        createdAt: new Date(),
        approvedBy: null
      })
    })
    .run()
})
