/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOverlay, Stack } from '@mantine/core'
import { createFormContext, zodResolver } from '@mantine/form'
import type { Effect, Store } from 'effector'
import { createStore } from 'effector'
import { useStore } from 'effector-react'
import type { Maybe } from 'purify-ts'
import { Nothing } from 'purify-ts'
import { mergeLeft } from 'ramda'
import type { PropsWithChildren } from 'react'
import type { ZodSchema } from 'zod'
import type { AnyFn } from '../../types/generic'

// eslint-disable-next-line functional/no-mixed-types
type OwnProps<T extends object> = PropsWithChildren<{
  onSubmit: Effect<any, any, any>
  store?: Store<Maybe<T>>
  schema: ZodSchema
  empty: T
}>

const [FormProvider, useFormContext, useForm] = createFormContext()
const dummyStore = createStore(Nothing)

const Form = <T extends object>({
  children,
  store = dummyStore as any,
  schema,
  empty,
  ...props
}: OwnProps<T>): JSX.Element => {
  const data = useStore(store)
  const isSaving = useStore(props.onSubmit.pending)

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: mergeLeft(data.extractNullable() ?? {}, empty)
  })

  return (
    <FormProvider form={form}>
      <LoadingOverlay visible={isSaving} transitionDuration={200} />
      <form onSubmit={form.onSubmit(props.onSubmit as AnyFn)}>
        <Stack spacing="sm">{children}</Stack>
      </form>
    </FormProvider>
  )
}

export { Form, useForm, useFormContext }
