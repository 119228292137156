/* eslint-disable functional/immutable-data */
import type { Dimension } from '../types/generic'

export const getImageDimensions = (file: File): Promise<Dimension> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      resolve({ width: img.width, height: img.height })
      URL.revokeObjectURL(img.src)
    }

    img.onerror = () => {
      reject(new Error('Failed to load the image.'))
      URL.revokeObjectURL(img.src)
    }
  })
