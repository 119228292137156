import { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

const LandingPage = lazy(() =>
  import('./ui/content/landing').then(module => ({ default: module.Landing }))
)

const FriendsPage = lazy(() =>
  import('./ui/content/friendships').then(module => ({ default: module.Friendships }))
)

const SchedulePage = lazy(() =>
  import('./ui/content/schedules').then(module => ({ default: module.Schedules }))
)

const StaticPage = lazy(() =>
  import('./ui/static-page').then(module => ({ default: module.StaticPage }))
)

export const Routing = (): JSX.Element => (
  <Routes>
    <Route path="/" Component={LandingPage} />
    <Route path="/friends" Component={FriendsPage} />
    <Route path="/schedules" Component={SchedulePage} />
    <Route path="/:slug" Component={StaticPage} />
  </Routes>
)
