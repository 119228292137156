import './services/google-auth'
import { Maybe } from 'purify-ts'
import { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AppTheme } from './ui/app-theme'
import { ErrorBoundary } from './utils/error-boundary'
import { throwError } from './utils/throw-error'
import { Routing } from './routing'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'

import('./stores/index')
import('./services/logic')

Maybe.fromNullable(document.getElementById('root'))
  .ifNothing(() => throwError('#root element not found'))
  .map(createRoot)
  .map(root =>
    root.render(
      <StrictMode>
        <AppTheme>
          <BrowserRouter>
            <ErrorBoundary>
              <Suspense>
                <ModalsProvider>
                  <Routing />
                </ModalsProvider>
              </Suspense>
              <Notifications position="bottom-right" limit={3} />
            </ErrorBoundary>
          </BrowserRouter>
        </AppTheme>
      </StrictMode>
    )
  )
