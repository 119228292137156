import type { TypeOf } from 'zod'
import { boolean, object, string } from 'zod'
import { timestamp } from './codecs/timestamp-codec'
import type { Profile } from './profile'
import type { Maybe } from 'purify-ts'

export const Friendship = object({
  owner: string(),
  friend: string(),
  notification: boolean(),
  createdAt: timestamp()
}).describe('Friendship')

export type Friendship = TypeOf<typeof Friendship> & { id: string }

export type UIFriendship = Friendship & {
  friendAvatar: Maybe<string>
  friendProfile: Maybe<Profile>
}
