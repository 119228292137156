/* eslint-disable functional/immutable-data */
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import firebase from '../../firebase.json'
import { firebaseConfig } from './config'

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'tassutoverit')

const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app, 'europe-west1')

storage.maxOperationRetryTime = 1

if (import.meta.env.DEV) {
  console.log('Running against emulator')
  connectFunctionsEmulator(functions, 'localhost', firebase.emulators.functions.port)
  connectStorageEmulator(storage, 'localhost', firebase.emulators.storage.port)
  connectFirestoreEmulator(db, 'localhost', firebase.emulators.firestore.port)
}

export { auth, db, storage, functions, app }
