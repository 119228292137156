import { Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import { deleteProfileFx } from './delete-profile'

export const confirmProfileDeleteFx = (): void =>
  modals.openConfirmModal({
    id: 'confirm-delete',
    title: <Text weight={600}>Vahvista, ole hyvä</Text>,
    withOverlay: true,
    overlayProps: {
      zIndex: 202
    },
    children: (
      <Text>
        Oletko varma, että haluat poistaa profiilisi? Tätä ei voi peruuttaa ja jos sinulla on
        premium-tili, se peruutetaan.
      </Text>
    ),
    labels: { confirm: 'Vahvista', cancel: 'Peruuta' },
    confirmProps: { color: 'red' },
    onConfirm: () => void deleteProfileFx(undefined)
  })
