export enum Collections {
  checkins = 'checkins',
  friendships = 'friendships',
  friendRequests = 'friendrequests',
  profiles = 'profiles',
  participants = 'participants',
  pictures = 'pictures',
  subscriptions = 'subscriptions',
  messageTokens = 'tokens',
  parkCache = 'parkcache'
}
