import type { HTMLAttributes, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { prop } from 'ramda'

type OwnProps = PropsWithChildren<{
  image: string
}> &
  HTMLAttributes<HTMLButtonElement>

export const Avatar = styled(
  ({ children, ...props }: OwnProps): JSX.Element => <button {...props}>{children}</button>
)`
  position: relative;
  border-radius: 100%;
  aspect-ratio: 1;
  appearance: none;
  border: 7px solid #fbfdfa;
  padding: 0;
  width: 100%;
  background-image: url(${prop('image')});
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
`
