import { createStore } from 'effector'
import type { User } from 'models/user'
import { Maybe, Nothing } from 'purify-ts'
import { userStatusEv } from '../events/events'
import { F } from 'ramda'

export const $user = createStore<Maybe<User>>(Nothing).on(userStatusEv, (_, payload) =>
  Maybe.fromNullable(payload)
)

export const $userLoading = createStore(true).on(userStatusEv, F)
