import { ReactComponent as Title } from '@img/title.svg'
import backdrop from '@img/backdrop.webp'
import styled from '@emotion/styled'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Login } from './components/landing-page/login'
import { keyframes } from '@emotion/react'

const rotate = keyframes`
  to {
    transform: scale(1.1) translateY(10%);
  }
`

const Header = styled.header`
  aspect-ratio: calc(20 / 8);
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    inset: 0;
    background-image: url(${backdrop});
    background-position: top center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  > a {
    position: absolute;
    top: 0.5rem;
    left: 2.5vw;
    width: 60%;
  }

  svg {
    animation: ${rotate} 2s ease-in-out infinite;
    animation-direction: alternate;
  }
`

type OwnProps = {
  showLogin?: boolean
}

export const PageHeader = ({ showLogin = false }: OwnProps): JSX.Element => {
  const headerRef = useRef<HTMLElement>(null)

  return (
    <Header ref={headerRef}>
      <Link to="/" aria-label="">
        <Title />
      </Link>
      {showLogin && <Login />}
    </Header>
  )
}
