import type { Geopoint } from 'geofire-common'
import { renameKeys } from 'ramda-adjunct'
import type { Position } from './../models/position'

type LatLngLiteral = {
  lng: number
  lat: number
}

const shortLoc = { longitude: 'lng', latitude: 'lat' } as const

export const toLiteral: (position: Position) => LatLngLiteral = renameKeys(shortLoc)
export const toGeoPoint = (position: Position): Geopoint => [position.latitude, position.longitude]
