import { getDownloadURL } from 'firebase/storage'
import type { User } from '../models/user'
import { profileRef } from '../services/storage'
import { imagePromise } from '../utils/image-promise'
import { createMaybeEffect } from '../utils/maybe'

export const loadProfileImageFx = createMaybeEffect((user: User) =>
  Promise.resolve(user).then(profileRef).then(getDownloadURL).then(imagePromise)
)

loadProfileImageFx.fail.watch(console.error)
