import { isNil } from 'ramda'
import type { ErrorInfo, PropsWithChildren, ReactNode } from 'react'
import { Component } from 'react'

import { Alert } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { MainPage } from '../ui/main-page'

type State = PropsWithChildren<{
  error?: Error
}>

export class ErrorBoundary<P extends PropsWithChildren<unknown>> extends Component<P, State> {
  constructor(props: P) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(errorInfo)
    this.setState({ error })
  }

  render(): ReactNode {
    if (!isNil(this.state.error)) {
      // You can render any custom fallback UI
      return (
        <MainPage>
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Voi ei! Sovellus on kaatunut."
            color="red"
            variant="filled"
          >
            {this.state.error.message}
          </Alert>
        </MainPage>
      )
    }

    return this.props.children
  }
}
