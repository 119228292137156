import pMemoize from 'p-memoize'
import { call } from './callable'
import ExpiryMap from 'expiry-map'
import { geohashForLocation } from 'geofire-common'
import { toGeoPoint } from '../shared/location'

const cache = new ExpiryMap(60 * 60 * 1000)

export const getDogParks = pMemoize(call('dogparks'), {
  cache,
  cacheKey: ([loc]) => geohashForLocation(toGeoPoint(loc), 7)
})

export const resize = call('resize')
export const prices = call('prices')
export const createCheckoutSession = call('createCheckoutSession')
export const portalLink = call('portalLink')
export const deleteProfile = call('deleteProfile')
export const deleteCheckin = call('deleteCheckin')
