import { createEvent } from 'effector'
import type { Maybe } from 'purify-ts'
import type { DogPark } from '../models/dogpark'
import type { FriendRequest } from '../models/friend-request'
import type { User } from '../models/user'
import type { Position } from '../models/position'
import type { Friendship } from '../models/friendship'
import type { Subscription } from '../models/subscription'
import type { Unsubscribe } from 'firebase/messaging'

// --- profile --- //
export const openProfileModalEv = createEvent<boolean>()
export const userStatusEv = createEvent<User | null>()

// --- checkin --- //
export const toggleDogparkSortEv = createEvent()
export const openSelectCheckinModalEv = createEvent<boolean>()
export const selectCheckinEv = createEvent<Maybe<DogPark>>()

// --- schedules --- //
export const reloadSchedulesEv = createEvent()

// --- friends --- //
export const closeFriendRequestModalEv = createEvent()
export const closeByFriendRequestEv = createEvent<FriendRequest>()
export const closeByFriendRequestCancelledEv = createEvent<FriendRequest>()
export const subscribeFriendRequestEv = createEvent<{ location: Position; user: User }>()
export const stopConfettiEv = createEvent()
export const startConfettiEv = createEvent()
export const subscribeFriendshipsEv = createEvent()
export const unsubscribeFriendshipsEv = createEvent()
export const friendShipsLoadedEv = createEvent<Friendship[]>()
export const trackedFriendshipsEv = createEvent<number>()
export const onMessageEv = createEvent<Unsubscribe>()

// --- gallery --- //
export const closeGalleryEv = createEvent()

// --- stripe --- //
export const closeStripeModalEv = createEvent()
export const subscriptionEv = createEvent<Maybe<Subscription>>()
