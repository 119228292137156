import type { Maybe} from 'purify-ts'
import { Just, Nothing } from 'purify-ts'
import type { ZodSchema, ZodTypeDef } from 'zod'

export const parseMaybe =
  <T>(codec: ZodSchema<T, ZodTypeDef, unknown>) =>
  (data: unknown): Maybe<T> => {
    const x = codec.safeParse(data)
    if (x.success) {
      return Just(x.data)
    } else {
      return Nothing
    }
  }
