import { map } from 'ramda'
import { loadPictures } from '../services/firestore'
import { addSpec } from '../shared/add-spec'
import type { Picture, UIPicture } from '../models/picture'
import { promiseAll, resolvePromised } from '../utils/resolve-promised'
import { getDownloadURL } from 'firebase/storage'
import { galleryRef } from '../services/storage'
import { createEffect } from 'effector'
import { imagePromise } from '../utils/image-promise'
import { loadGalleryProfileFx } from './load-gallery-profile'

export const loadGalleryPicturesFx = createEffect(async (userId: string): Promise<UIPicture[]> => {
  const url = (pic: Picture): Promise<string> =>
    getDownloadURL(galleryRef(userId, pic.id)).then(imagePromise)

  await loadGalleryProfileFx(userId)

  return loadPictures(userId)
    .then(map(addSpec({ url })))
    .then(map(resolvePromised))
    .then(promiseAll)
})

loadGalleryPicturesFx.fail.watch(console.error)
