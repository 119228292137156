import { createEffect } from 'effector'
import { Maybe, MaybeAsync } from 'purify-ts'
import { propEq } from 'ramda'
import type { ApprovedFriendRequest } from '../models/friend-request'
import { createFriendship, deleteFriendRequests } from '../services/firestore'
import type { User } from '../models/user'
import { startConfettiEv } from './events'

type Establish = {
  user: User
  requests: [ApprovedFriendRequest, ApprovedFriendRequest]
}

export const establishFriendshipFx = createEffect(async ({ requests, user }: Establish) => {
  // each user creates own side of the friendship
  const mySide = Maybe.fromNullable(requests.find(propEq(user.id, 'initiator')))
  await MaybeAsync.liftMaybe(mySide)
    .map(r =>
      createFriendship({
        owner: r.initiator,
        friend: r.approvedBy,
        notification: false,
        createdAt: new Date()
      })
    )
    .run()
  await deleteFriendRequests(user).then(() => startConfettiEv())
})

establishFriendshipFx.fail.watch(console.error)
