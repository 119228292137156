import { uploadFile } from '../services/storage'
import { type Maybe } from 'purify-ts'
import type { User } from '../models/user'
import { createMaybesEffect } from '../utils/maybe'

type Upload = {
  user: Maybe<User>
  file: File
}

export const uploadProfileImageFx = createMaybesEffect<Upload, void>(async ({ user, file }) => {
  const upload = uploadFile(user.id, 'profile', [400, 100])
  await upload(file)
})
