import { GalleryHeight } from '../../../config'
import type { UIPicture } from '../../../models/picture'

type OwnProps = {
  picture: UIPicture
}

export const GalleryPicture = ({ picture }: OwnProps): JSX.Element => (
  <a
    href={picture.url}
    target="_blank"
    style={{
      display: 'block',
      maxWidth: '100%',
      width: `${GalleryHeight * picture.aspectRatio}px`,
      height: `${GalleryHeight}px`
    }}
    rel="noreferrer"
  >
    <img src={picture.url} alt="" width="100%" />
  </a>
)
