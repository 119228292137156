import type { Maybe } from 'purify-ts'
import type { Profile } from '../models/profile'
import type { User } from '../models/user'
import { storeProfile } from '../services/firestore'
import { createMaybesEffect } from '../utils/maybe'

type SaveProfile = {
  user: Maybe<User>
  profile: Profile
}

export const saveProfileFx = createMaybesEffect<SaveProfile, Profile>(({ user, profile }) =>
  storeProfile(profile, user).then(() => profile)
)
