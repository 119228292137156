import type { DocumentSnapshot } from 'firebase/firestore'
import type { Maybe } from 'purify-ts'
import { Just, Nothing } from 'purify-ts'
import type { Id } from '../services/firestore'
import type { Schema } from 'zod'

export const docToMaybe =
  <T>(codec: Schema<T>) =>
  (doc: DocumentSnapshot<T>): Maybe<Id<T>> => {
    if (doc.exists()) {
      const data = codec.safeParse(doc.data())
      if (data.success) {
        return Just<Id<T>>({ id: doc.id, ...data.data })
      }
    }
    return Nothing
  }
