/* eslint-disable functional/immutable-data */
import { GoogleAuthProvider, getAuth, signInWithRedirect } from 'firebase/auth'
import { app } from './firebase'
import { userStatusEv } from '../events/events'

const provider = new GoogleAuthProvider()
const auth = getAuth(app)
auth.languageCode = 'fi'

export const loginWithGoogle = async (): Promise<void> => {
  signInWithRedirect(auth, provider)
}

export const logoutWithGoogle = (): Promise<void> =>
  auth
    .signOut()
    .then(() => console.log('Logout complete'))
    .catch(console.warn)

auth.onAuthStateChanged(user => {
  userStatusEv(user?.email ? { email: user.email, id: user.uid } : null)
})
