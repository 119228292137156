import type { HTMLAttributes, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import bubble from '@img/speech-bubble.svg'
import { keyframes } from '@emotion/react'

const scaleUp = keyframes`
    0% {
      transform: translate(-75%, -40%) scale(0);
      opacity: 0;
    }
    5%, 50% {
        transform: translate(-80%, -50%) scale(1);
        opacity: 1;
    }
    55% {
      transform: translate(-75%, -40%) scale(0);
      opacity: 0;
    }
    100% {
      transform: translate(-75%, -40%) scale(0);
      opacity: 0;
    }
`

export const SpeechBubble = styled(
  ({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): JSX.Element => (
    <div {...props}>{children}</div>
  )
)`
  font-style: italic;
  position: absolute;
  width: 100%;
  aspect-ratio: calc(615 / 348);
  background-image: url(${bubble});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  transform-origin: 90% 95%;
  animation: ${scaleUp} 9s infinite;
  animation-delay: 2s;
  opacity: 0;
  pointer-events: none;
  padding: 0.75rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 120%;
  font-weight: 600;
  font-size: 12px;
`
