import { deletePicture, storePicture } from '../services/firestore'
import type { Maybe } from 'purify-ts'
import type { User } from '../models/user'
import { createMaybesEffect } from '../utils/maybe'
import { uploadFile } from '../services/storage'
import { getImageDimensions } from '../utils/file-image-dimensions'

type UploadPicture = {
  user: Maybe<User>
  file: File
}

export const uploadGalleryPictureFx = createMaybesEffect<UploadPicture, void>(({ user, file }) =>
  getImageDimensions(file)
    .then(dimension =>
      storePicture({
        aspectRatio: dimension.width / dimension.height,
        dimension,
        owner: user.id
      })
    )
    .then(id =>
      uploadFile(user.id, `gallery_${id}`, [400, 1200])(file).catch(() => deletePicture(id))
    )
)
