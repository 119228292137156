import type { ModalBaseOverlayProps } from '@mantine/core'
import { Sex } from './models/profile'
import type { ManipulateType } from 'dayjs'
import type { Meters } from './types/generic'

export const locale = 'fi-FI'

export const CloseByParkLimit = 6
export const ParticipantsPerSchedule = 4
export const AvatarSize = 60
export const Green = {
  dark: '#729a79',
  bright: '#dcead2'
}
export const DateFormat = 'D MMM, HH.mm'
export const CheckinMaxDate: [number, ManipulateType] = [5, 'weeks']
export const FriendRequestRadius = 500 as Meters
export const GalleryHeight = 180
export const GalleryMaxImages = 10

export const contentApiUrl =
  'https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clfww3s2k5ojd01ue7emserrj/master'

export const overlayProps: ModalBaseOverlayProps = {
  opacity: 0.55,
  blur: 3,
  color: 'rgba(0, 0, 0, 25)'
}

export const sex = [
  { label: 'Narttu', value: Sex.female },
  { label: 'Uros', value: Sex.male }
]

export const ProductionPayments = false
export const ProductId = ProductionPayments ? 'prod_NmFWVwLxkQBTpz' : 'prod_NlyYLyOUl1Gnhc'
