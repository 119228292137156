import ExpiryMap from 'expiry-map'
import type {
  CollectionReference,
  DocumentChange,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe
} from 'firebase/firestore'
import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore'
import { distanceBetween, geohashQueryBounds } from 'geofire-common'
import pMemoize from 'p-memoize'
import type { Maybe } from 'purify-ts'
import { Nothing, curry } from 'purify-ts'
import {
  allPass,
  andThen,
  filter,
  flatten,
  head,
  isNil,
  map,
  pipe,
  prop,
  propEq,
  reject,
  splitEvery,
  unless,
  when
} from 'ramda'
import { lengthEq } from 'ramda-adjunct'
import type { Schema, ZodSchema } from 'zod'
import { establishFriendshipFx } from '../events/establish-friendship'
import { closeByFriendRequestCancelledEv, closeByFriendRequestEv } from '../events/events'
import type { Checkin } from '../models/checkin'
import type { ApprovedFriendRequest, UIFriendRequest } from '../models/friend-request'
import { FriendRequest } from '../models/friend-request'
import { Friendship } from '../models/friendship'
import { Participant } from '../models/participant'
import type { Position } from '../models/position'
import { Profile } from '../models/profile'
import type { User } from '../models/user'
import { toGeoPoint } from '../shared/location'
import type { AnyFn, ID, Meters, NoId } from '../types/generic'
import { docToMaybe } from '../utils/doc-to-maybe'
import { parseMaybe } from '../utils/parse-maybe'
import { db } from './firebase'
import { Picture } from '../models/picture'
import { Collections } from '../shared/collections'
import { promiseAll } from '../utils/resolve-promised'
import { Token } from '../models/token'

type Col<T> = CollectionReference<T>

const profiles = collection(db, Collections.profiles) as Col<Profile>
const checkins = collection(db, Collections.checkins) as Col<Checkin>
const friendships = collection(db, Collections.friendships) as Col<Friendship>
const participants = collection(db, Collections.participants) as Col<Participant>
const friendRequests = collection(db, Collections.friendRequests) as Col<FriendRequest>
const pictures = collection(db, Collections.pictures) as Col<Picture>
const messageTokens = collection(db, Collections.messageTokens) as Col<Token>

const cache = new ExpiryMap(5 * 60 * 1000)

export const bustCache = (): void => cache.clear()

export const loadProfile = pMemoize(
  (userId: string): Promise<Maybe<Profile>> => {
    const docRef = doc(profiles, userId)
    return getDoc(docRef)
      .then(docToMaybe(Profile))
      .catch(() => Nothing)
  },
  { cache }
)

export const loadParticipants: (checkin: Checkin[]) => Promise<Participant[]> = pipe(
  map(prop('id')),
  splitEvery(30),
  map((ids: string[]) => {
    const q = query(participants, where('checkinId', 'in', ids))
    return getDocs(q).then(toArray(Participant))
  }),
  promiseAll,
  andThen(flatten)
)

export const loadInverseFriendships = (userId: string): Promise<Friendship[]> => {
  const q = query(friendships, where('friend', '==', userId))
  return getDocs(q).then(toArray(Friendship))
}

export const loadPictures = (userId: string): Promise<Picture[]> => {
  const q = query(pictures, where('owner', '==', userId))
  return getDocs(q).then(toArray(Picture))
}

export const storePicture = (picture: NoId<Picture>): Promise<ID> =>
  addDoc(pictures, picture).then(prop('id'))

export const deletePicture = (pictureId: string): Promise<void> =>
  deleteDoc(doc(pictures, pictureId))

export const storeProfile = curry((profile: Profile, user: User): Promise<void> => {
  const docRef = doc(profiles, user.id)
  return setDoc(docRef, profile)
})

export const updateProfile = curry((profile: Partial<Profile>, user: User): Promise<void> => {
  const docRef = doc(profiles, user.id)
  return updateDoc(docRef, profile)
})

export const getUserToken = (user: User): Promise<Maybe<Token>> => {
  const docRef = doc(messageTokens, user.id)
  return getDoc(docRef)
    .then(d => d.data())
    .then(parseMaybe(Token))
}

export const storeToken = curry((token: string, user: User): Promise<void> => {
  const docRef = doc(messageTokens, user.id)
  return setDoc(docRef, { token, created: new Date() }, { merge: false })
})

export const deleteToken = (user: User): Promise<void> => {
  const docRef = doc(messageTokens, user.id)
  return deleteDoc(docRef)
}

const participantRef = (participant: Participant): DocumentReference =>
  doc(participants, `${participant.checkinId}_${participant.userId}`)

export const storeParticipant = curry(
  (participant: Participant): Promise<void> => setDoc(participantRef(participant), participant)
)

export const deleteParticipant = curry(
  (participant: Participant): Promise<void> => deleteDoc(participantRef(participant))
)

export const storeCheckin = (checkin: NoId<Checkin>): Promise<ID> =>
  addDoc(checkins, checkin).then(prop('id'))

export type Id<T> = T & { id: string }

export const toArray =
  <T>(codec: ZodSchema<T>) =>
  (doc: QuerySnapshot<T>): Id<T>[] =>
    doc.docs.reduce((acc: Id<T>[], el: QueryDocumentSnapshot<T>) => {
      const res = codec.safeParse(el.data())
      if (res.success) {
        return [...acc, { id: el.id, ...res.data }]
      } else {
        console.log(res.error)
        return acc
      }
    }, [])

export const parse =
  <T>(codec: Schema<T>) =>
  (doc: DocumentChange<T>): Id<T> => {
    const data = codec.parse(doc.doc.data())
    return { ...data, id: doc.doc.id }
  }

export const deleteFriendRequests = async (user: User): Promise<void> => {
  const oldRequests = query(friendRequests, where('initiator', '==', user.id))
  const batch = writeBatch(db)
  await getDocs(oldRequests).then(snapshot => snapshot.forEach(doc => batch.delete(doc.ref)))
  await batch.commit()
}

export const createFriendRequest = async (
  user: User,
  request: NoId<FriendRequest>
): Promise<ID> => {
  await deleteFriendRequests(user)
  return addDoc(friendRequests, request).then(prop('id'))
}

export const confirmRequest = async (user: User, request: UIFriendRequest): Promise<void> => {
  const friendRequest = doc(friendRequests, request.requestId)
  return updateDoc(friendRequest, { approvedBy: user.id })
}

const radiusLessThan =
  (center: Position, radius: Meters) =>
  (req: FriendRequest): boolean => {
    const distance = (distanceBetween([req.latitude, req.longitude], toGeoPoint(center)) *
      1000) as Meters
    return distance <= radius
  }

export const subscribeFriendRequests = (
  user: User,
  center: Position,
  radius: Meters
): Unsubscribe[] =>
  geohashQueryBounds(toGeoPoint(center), radius).map(([from, to]) =>
    onSnapshot(
      query(friendRequests, orderBy('geohash'), startAt(from), endAt(to)),
      pipe(
        toArray(FriendRequest),
        reject(propEq(user.id, 'initiator')),
        filter(radiusLessThan(center, radius)),
        list => head(list),
        unless(isNil, closeByFriendRequestEv)
      )
    )
  )

export const subscribeFriendRequestCancel = (friendId: string): Unsubscribe =>
  onSnapshot(query(friendRequests, where('initiator', '==', friendId)), s =>
    s
      .docChanges()
      .filter(propEq('removed', 'type') as AnyFn) // types are broken in @types/ramda 0.29
      .map(parse(FriendRequest))
      .forEach(closeByFriendRequestCancelledEv)
  )

const isApprovedFriendship = allPass([
  lengthEq(2),
  ([first, second]) =>
    first.approvedBy === second.initiator && second.approvedBy === first.initiator
]) as (req: FriendRequest[]) => req is [ApprovedFriendRequest, ApprovedFriendRequest]

export const subscribeFriendships = (user: User): Unsubscribe => {
  const confirmed = query(
    friendRequests,
    or(where('initiator', '==', user.id), where('approvedBy', '==', user.id))
  )
  return onSnapshot(
    confirmed,
    pipe(
      toArray(FriendRequest),
      when(isApprovedFriendship, requests => establishFriendshipFx({ user, requests }))
    )
  )
}

export const createFriendship = async (friendship: NoId<Friendship>): Promise<void> => {
  const friendSide = and(
    where('initiator', '==', friendship.friend),
    where('approvedBy', '==', friendship.owner)
  )
  const stillValid = query(friendRequests, friendSide)
  if ((await getDocs(stillValid)).empty) {
    throw Error('request has been cancelled prematurely')
  }
  const q = query(
    friendships,
    and(where('owner', '==', friendship.owner), where('friend', '==', friendship.friend))
  )
  const docs = await getDocs(q)
  if (docs.empty) {
    await addDoc(friendships, friendship)
    return
  }
  throw Error('friendship already exists')
}

export const deleteFriendship = async (userId: string, friendId: string): Promise<void> => {
  const q = query(friendships, and(where('owner', '==', userId), where('friend', '==', friendId)))
  return await getDocs(q)
    .then(
      pipe(
        s => s.docs,
        map(snap => deleteDoc(snap.ref)),
        promiseAll
      )
    )
    .then()
}

export const toggleNotification = curry(
  (notification: boolean, friendship: Friendship): Promise<void> =>
    updateDoc(doc(friendships, friendship.id), { notification })
)

export const unfriend = async (friendship: Friendship): Promise<void> =>
  deleteDoc(doc(friendships, friendship.id))
