/* eslint-disable @typescript-eslint/no-explicit-any */
import { when } from 'ramda'
import type { ZodDate } from 'zod'
import { date, preprocess } from 'zod'

type Timestamp = {
  toDate: () => Date
}

const isFirebaseTimestamp = (value: any): value is Timestamp => 'toDate' in (value ?? {})

const firebaseTimestampToDate = (value: Timestamp): Date => value.toDate()

export const timestamp = (): ZodDate =>
  preprocess(when(isFirebaseTimestamp, firebaseTimestampToDate), date()) as any
