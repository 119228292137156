import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { timestamp } from './codecs/timestamp-codec'

export const Token = object({
  token: string(),
  created: timestamp()
}).describe('Token')

export type Token = TypeOf<typeof Token>
