import 'dayjs/locale/fi'

import { Button, Group, SimpleGrid, Stack, Text } from '@mantine/core'
import { useStore } from 'effector-react'
import { sex } from '../../config'
import { $profile } from '../../stores/profile'
import { PhotoUpload } from '../components/landing-page/photo-upload'

import { DatePickerInput } from '../form-context/date-picker-input'
import { Form } from '../form-context/form'
import { SegmentedControl } from '../form-context/segmented-control'
import { Select } from '../form-context/select'
import { Textarea } from '../form-context/text-area'
import { TextInput } from '../form-context/text-input'
import { SmartModal } from '../components/smart-modal'
import { $profileModal } from '../../stores/modals'
import { $user } from '../../stores/user'
import { attach } from 'effector'
import { openProfileModalEv } from '../../events/events'
import { saveProfileFx } from '../../events/save-profile'
import { Profile as Schema, Sex, Size } from '../../models/profile'
import { confirmProfileDeleteFx } from '../../events/confirm-delete-profile'

const saveProfile = attach({
  effect: saveProfileFx,
  source: $user,
  mapParams: (profile: Schema, user) => ({ user, profile })
})

export const Profile = (): JSX.Element => {
  const open = useStore($profileModal)

  return (
    <SmartModal
      opened={open}
      onClose={() => openProfileModalEv(false)}
      title={<Text weight={600}>Profiili</Text>}
      withCloseButton
    >
      <Form
        onSubmit={saveProfile}
        store={$profile}
        schema={Schema}
        empty={{
          id: '',
          name: '',
          sex: Sex.female,
          size: Size.medium,
          breed: '',
          birthdate: new Date(),
          description: ''
        }}
      >
        <Group noWrap w="100%" align="flex-start">
          <PhotoUpload />
          <Stack spacing="sm" style={{ flexGrow: 1 }}>
            <TextInput label="Koiran nimi" withAsterisk name="name" />
            <SegmentedControl data={sex} name="sex" />
          </Stack>
        </Group>
        <TextInput placeholder="Basenji" label="Rotu" name="breed" />
        <Select
          name="size"
          label="Koko"
          data={[
            { value: Size.tiny, label: 'Pikkuruinen' },
            { value: Size.small, label: 'Pieni' },
            { value: Size.medium, label: 'Keskikokoinen' },
            { value: Size.large, label: 'Suuri' },
            { value: Size.giant, label: 'Jättiläinen' }
          ]}
        />
        <DatePickerInput
          locale="fi"
          placeholder="Valitse..."
          label="Syntymäpäivä"
          name="birthdate"
          maxDate={new Date()}
          popoverProps={{ zIndex: 1000 }}
        />
        <Textarea label="Kuvaus" name="description" />
        <SimpleGrid spacing="sm" cols={2}>
          <Button color="red" variant="outline" onClick={() => confirmProfileDeleteFx()}>
            Poista profiili
          </Button>
          <Button type="submit">Tallenna</Button>
        </SimpleGrid>
      </Form>
    </SmartModal>
  )
}
