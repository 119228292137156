import { httpsCallable } from 'firebase/functions'
import { prop } from 'ramda'
import type { API } from '../models/api'
import { functions } from './firebase'

export const call =
  <F extends keyof API>(func: F): API[F] =>
  arg => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rpc = httpsCallable<Parameters<API[F]>[0], ReturnType<API[F]>>(functions, func) as any
    return rpc(arg).then(prop('data'))
  }
