import { FileInput, LoadingOverlay } from '@mantine/core'
import { IconPhotoPlus } from '@tabler/icons-react'
import styled from '@emotion/styled'
import { useStore, useStoreMap } from 'effector-react'
import { when } from 'ramda'
import { isNotNull } from 'ramda-adjunct'
import { $profilePicture } from '../../../stores/profile'
import { uploadProfileImageFx } from '../../../events/upload-profile-image'
import { attach } from 'effector'
import { $user } from '../../../stores/user'

const Frame = styled.div`
  flex-shrink: 0;
  min-width: 140px;
  background-color: #e0e0e0;
  border-radius: 3px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  button {
    height: 100px;
    * {
      visibility: hidden;
    }
  }
`
const upload = attach({
  effect: uploadProfileImageFx,
  source: [$user],
  mapParams: (file: File, [user]) => ({
    user,
    file
  })
})

export const PhotoUpload = (): JSX.Element => {
  const uploadPending = useStore(uploadProfileImageFx.pending)

  const currentPicture = useStoreMap($profilePicture, pic =>
    pic.caseOf({
      Just: (url: string) => (
        <div
          style={{
            inset: 0,
            position: 'absolute',
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      ),
      Nothing: () => <IconPhotoPlus size={32} color="white" />
    })
  )

  return (
    <Frame>
      <LoadingOverlay visible={uploadPending} />
      {currentPicture}
      <FileInput
        variant="unstyled"
        style={{
          color: 'transparent',
          inset: 0,
          position: 'absolute'
        }}
        onChange={when(isNotNull, upload)}
      />
    </Frame>
  )
}
