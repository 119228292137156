import styled from '@emotion/styled'
import google from '@img/google_on_white_hdpi.png'
import { useEvent, useStore } from 'effector-react'
import { logoutFx } from 'events/logout'
import { gql } from 'graphql-request'
import { defaultTo, map, pipe, prop } from 'ramda'
import { Link, useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'
import { hygraph } from '../services/hygraph'
import { $user } from '../stores/user'
import { LinkBtn } from './components/link-btn'
import type { PageLink } from './types/hygraph'
import { Group, Center, Stack } from '@mantine/core'

const PoweredBy = styled.p`
  align-self: center;
  position: relative;
  text-align: center;

  span {
    font-size: 0.5rem;
    display: block;
  }

  img {
    height: 1rem;
    width: auto;
  }
`

const query = gql`
  query Pages {
    pages(where: { footer: true }) {
      link
      slug
    }
  }
`

export const Footer = (): JSX.Element => {
  const user = useStore($user)
  const navigate = useNavigate()
  const logout = useEvent(logoutFx)

  const { value: links } = useAsync(
    () =>
      hygraph.request<{ pages: PageLink[] }>(query).then<JSX.Element[]>(
        pipe(
          prop('pages'),
          defaultTo([]),
          map<PageLink, JSX.Element>(({ slug, link }) => (
            <Link key={slug} to={'/' + slug}>
              {link}
            </Link>
          ))
        )
      ),
    []
  )

  return (
    <Center px="1rem">
      <Stack spacing="xs">
        <PoweredBy>
          <span>Powered by</span>
          <img src={google} alt="" />
        </PoweredBy>
        <Group spacing="sm" align="end" py="1rem" position="center">
          {links}
          {user.isJust() && (
            <LinkBtn onClick={() => logout().then(() => navigate('/'))}>Kirjaudu ulos</LinkBtn>
          )}
        </Group>
      </Stack>
    </Center>
  )
}
