import type { TypeOf } from 'zod'
import { number, object, string } from 'zod'
import { timestamp } from './codecs/timestamp-codec'
import type { Profile } from './profile'

export const FriendRequest = object({
  initiator: string(),
  geohash: string(),
  createdAt: timestamp(),
  longitude: number(),
  latitude: number(),
  approvedBy: string().nullable()
}).describe('Friendrequest')

export type FriendRequest = TypeOf<typeof FriendRequest> & { id: string }

export type ApprovedFriendRequest = FriendRequest & { approvedBy: string }

export type UIFriendRequest = {
  ownProfile: Profile
  ownAvatar: string
  friendProfile: Profile
  friendAvatar: string
  createAt: Date
  requestId: string
}
