import styled from '@emotion/styled'
import { Button, FileInput } from '@mantine/core'
import { useEvent } from 'effector-react'
import { IconPhotoPlus } from '@tabler/icons-react'
import { uploadGalleryPictureFx } from '../../../events/upload-gallery-picture'
import { attach } from 'effector'
import { $user } from '../../../stores/user'

const IconWrap = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`
const onFileChangedFx = attach({
  effect: uploadGalleryPictureFx,
  source: $user,
  mapParams: (file: File, user) => ({ file, user })
})

type OwnProps = {
  disabled?: boolean
}

export const UploadButton = ({ disabled = false }: OwnProps): JSX.Element => {
  const onFileChange = useEvent(onFileChangedFx)
  return (
    <IconWrap>
      <FileInput
        disabled={disabled}
        variant="unstyled"
        accept="image/jpeg, image/png"
        style={{
          zIndex: 1,
          inset: 0,
          position: 'absolute',
          color: 'transparent'
        }}
        styles={{
          input: {
            color: 'transparent'
          }
        }}
        onChange={onFileChange}
      />
      <Button leftIcon={<IconPhotoPlus />}>Lisää kuva</Button>
    </IconWrap>
  )
}
