import styled from '@emotion/styled'
import login from '@img/login.webp'
import { Loader, keyframes } from '@mantine/core'
import { useStore, useStoreMap } from 'effector-react'
import { loadProfileFx } from '../../../events/load-profile'
import { loadProfileImageFx } from '../../../events/load-profile-image'
import { loginWithGoogle } from '../../../services/google-auth'
import { $profile, $profilePicture } from '../../../stores/profile'
import { $user, $userLoading } from '../../../stores/user'
import { Avatar } from '../schedules/avatar'
import { SpeechBubble } from './speech-bubble'
import { openProfileModalEv } from '../../../events/events'

const rotate = keyframes`
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
`
const LoginButton = styled.button`
  cursor: pointer;
  position: absolute;
  color: transparent;
  width: 100%;
  background: url(${login});
  background-repeat: no-repeat;
  background-size: contain;
  background-clip: padding-box;
  appearance: none;
  background-size: 100%;
  background-position: 50% 50%;
  border: 10px solid transparent;
  border-radius: 100%;
  background-clip: border-box;
  animation: ${rotate} 2s ease-in-out infinite;
  animation-direction: alternate;
  inset: 0;
`

const Name = styled.h2`
  position: absolute;
  transform: translate(-100%, 30%);
  left: -10px;
  bottom: 50%;
  font-weight: 800;
  color: white;
  -webkit-text-stroke: 0.25pt black;
  font-size: min(6vw, 40px);
  text-shadow: rgba(0, 0, 0, 0.56) 1px 1px 2px;
`

const LoginFrame = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  z-index: 2;
  width: 30%;
  aspect-ratio: 1 / 1;
  bottom: -40%;
  right: 5%;
`

export const Login = (): JSX.Element | null => {
  const user = useStore($user)
  const userLoading = useStore($userLoading)
  const profile = useStore($profile)
  const loadingProfileImage = useStore(loadProfileImageFx.pending)
  const loadingProfile = useStore(loadProfileFx.pending)

  const profilePicture = useStoreMap($profilePicture, pic =>
    pic.caseOf({
      Just: url => <Avatar onClick={() => openProfileModalEv(true)} image={url} />,
      Nothing: () => (
        <>
          <SpeechBubble>Luo profiilisi!</SpeechBubble>
          <LoginButton onClick={() => openProfileModalEv(true)}>Profiili</LoginButton>
        </>
      )
    })
  )

  if (userLoading || loadingProfileImage || loadingProfile) {
    return (
      <LoginFrame>
        <Loader size="sm" color="white" />
      </LoginFrame>
    )
  }

  return (
    <LoginFrame>
      {user.caseOf({
        Just: () => (
          <>
            {profilePicture}
            {profile.mapOrDefault(
              ({ name }) => (
                <Name>{name}</Name>
              ),
              null
            )}
          </>
        ),
        Nothing: () => (
          <>
            <SpeechBubble>Klikkaa tästä aloittaaksesi</SpeechBubble>
            <LoginButton onClick={() => loginWithGoogle()}>Login</LoginButton>
          </>
        )
      })}
    </LoginFrame>
  )
}
