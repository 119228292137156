import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Notification,
  Stack,
  ThemeIcon,
  Text
} from '@mantine/core'
import { useEvent, useStore, useStoreMap } from 'effector-react'
import { $galleryProfile, $uiPictures } from '../../stores/gallery'
import { closeGalleryEv } from '../../events/events'
import { UploadButton } from '../components/gallery/controls'
import { uploadGalleryPictureFx } from '../../events/upload-gallery-picture'
import type { Embla } from '@mantine/carousel'
import { Carousel } from '@mantine/carousel'
import { NonEmptyList } from '../components/non-empty-list'
import { GalleryPicture } from '../components/gallery/gallery-picture'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { useLayoutEffect, useState } from 'react'
import { GalleryHeight, GalleryMaxImages } from '../../config'
import { deleteGalleryPictureFx } from '../../events/delete-gallery-picture'
import { eqBy, liftN, prop } from 'ramda'
import { $profile } from '../../stores/profile'
import type { Maybe } from 'purify-ts'
import type { AnyFn } from '../../types/generic'

const eqById: <T>(a: Maybe<T>, b: Maybe<T>) => Maybe<boolean> = liftN(2, eqBy(prop('id'))) as AnyFn

export const GalleryModal = (): JSX.Element => {
  const userProfile = useStore($profile)
  const isGalleryOwner = useStoreMap($galleryProfile, p => eqById(p, userProfile).orDefault(false))
  const [index, setIndex] = useState(0)
  const [embla, setEmbla] = useState<Embla | null>(null)
  const profile = useStore($galleryProfile)
  const pictures = useStore($uiPictures)
  const close = useEvent(closeGalleryEv)
  const deletePicture = useEvent(deleteGalleryPictureFx)
  const uploading = useStore(uploadGalleryPictureFx.pending)
  const deleting = useStore(deleteGalleryPictureFx.pending)

  useLayoutEffect(() => {
    setTimeout(() => embla?.reInit(), 200)
  }, [embla])

  return (
    <Modal
      opened
      onClose={() => close()}
      withCloseButton
      title={profile.map(p => p.name).orDefault('')}
    >
      <LoadingOverlay visible={uploading || deleting} />
      <Stack spacing="sm">
        <Carousel
          slideGap=".25rem"
          align="center"
          controlsOffset="xs"
          onSlideChange={setIndex}
          controlSize={30}
          key={pictures.length}
          getEmblaApi={setEmbla}
          withIndicators
          height={GalleryHeight}
          slideSize="auto"
          nextControlIcon={
            <ThemeIcon variant="filled" color="blue" radius="xl">
              <IconChevronRight />
            </ThemeIcon>
          }
          previousControlIcon={
            <ThemeIcon variant="filled" color="blue" radius="xl">
              <IconChevronLeft />
            </ThemeIcon>
          }
          styles={{
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default'
              }
            }
          }}
        >
          <NonEmptyList list={pictures}>
            {picture => (
              <Carousel.Slide key={picture.id}>
                <GalleryPicture picture={picture} />
              </Carousel.Slide>
            )}
          </NonEmptyList>
          {pictures.length === 0 && (
            <Group p="xs">
              <Notification title="Ei kuvia" withCloseButton={false} withBorder>
                {isGalleryOwner ? (
                  <Text>
                    Sinulla ei ole vielä kuvia galleriassasi. Voit ladata jopa 10 kuvaa koirastasi.
                  </Text>
                ) : (
                  <Text>Näyttää siltä, että ystäväsi ei ole vielä ladannut mitään kuvia.</Text>
                )}
              </Notification>
            </Group>
          )}
        </Carousel>
        {isGalleryOwner ? (
          <Group position="apart">
            <Button variant="outline" color="red" onClick={() => deletePicture(pictures[index])}>
              Poista kuva
            </Button>
            <UploadButton disabled={pictures.length >= GalleryMaxImages} />
          </Group>
        ) : (
          <Group position="right">
            <Button variant="filled" onClick={close}>
              Sulje
            </Button>
          </Group>
        )}
      </Stack>
    </Modal>
  )
}
