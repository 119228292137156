import { createStore } from 'effector'
import { loadGalleryPicturesFx } from '../events/load-gallery-pictures'
import { F, T, nthArg } from 'ramda'
import { closeGalleryEv } from '../events/events'
import type { UIPicture } from '../models/picture'
import type { Maybe } from 'purify-ts'
import { Nothing } from 'purify-ts'
import type { Profile } from '../models/profile'
import { loadGalleryProfileFx } from '../events/load-gallery-profile'

export const $galleryOpen = createStore(false)
  .on(loadGalleryPicturesFx.doneData, T)
  .on(closeGalleryEv, F)

export const $uiPictures = createStore<UIPicture[]>([]).on(
  loadGalleryPicturesFx.doneData,
  nthArg(1)
)

export const $galleryProfile = createStore<Maybe<Profile>>(Nothing)
  .on(loadGalleryProfileFx.doneData, nthArg(1))
  .on(closeGalleryEv, () => Nothing)

export const $loadingGallery = createStore<string | null>(null)
  .on(loadGalleryPicturesFx, nthArg(1))
  .reset(loadGalleryPicturesFx.done)
