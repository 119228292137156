/* eslint-disable @typescript-eslint/no-explicit-any */
import { Maybe, MaybeAsync } from 'purify-ts'
import { values, pipe, zip, keys, fromPairs, complement, mergeRight, pickBy } from 'ramda'
import type { AnyFn } from '../types/generic'
import type { Effect } from 'effector'
import { createEffect } from 'effector'

type MaybeProps<T> = {
  [K in keyof T]: Maybe<T[K]>
}

type Resolved<T> = {
  [K in keyof T]: T[K] extends Maybe<infer R> ? R : T[K]
}

export const resolveMaybe = <T>(obj: MaybeProps<T>): Maybe<T> =>
  pipe(values, Maybe.sequence as AnyFn, m => m.map(pipe(zip(keys(obj)) as AnyFn, fromPairs)))(obj)

export const createMaybesEffect = <T, R>(
  fn: (i: Resolved<T>) => Promise<R | Maybe<R>>
): Effect<T, Maybe<R>, Error> =>
  createEffect((obj: T) => {
    const maybes: MaybeProps<Partial<T>> = pickBy(Maybe.isMaybe, obj)
    const notMaybes: Partial<T> = pickBy(complement(Maybe.isMaybe), obj)
    return MaybeAsync.liftMaybe(resolveMaybe(maybes))
      .chain(partial =>
        fn(mergeRight(partial, notMaybes) as any).then(res =>
          Maybe.isMaybe(res) ? res : Maybe.of(res)
        )
      )
      .run() as Promise<Maybe<R>>
  })

export const createMaybeEffect = <T, R>(
  fn: (i: T) => Promise<R>
): Effect<Maybe<T>, Maybe<R>, Error> =>
  createEffect((input: Maybe<T>) =>
    MaybeAsync.liftMaybe(input)
      .chain(e => fn(e).then<Maybe<R>>((res: any) => (Maybe.isMaybe(res) ? res : Maybe.of(res))))
      .run()
  )
