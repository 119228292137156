import styled from '@emotion/styled'
import contentFrame from '@img/content-frame.svg'

import { PageHeader } from './page-header'
import { Footer } from './footer'
import { type PropsWithChildren } from 'react'
import { Container } from '@mantine/core'
import { Profile } from './content/profile'
import { Gallery } from './components/gallery/gallery'

const Section = styled.section`
  position: relative;
  flex: 1;
  padding: 3rem 1rem;
  min-height: calc(100vh - 25vw - 80px);
  margin-top: -1px;

  @media (min-width: 481px) {
    padding: 7rem 4rem;
  }

  background-image: url(${contentFrame});
  background-repeat: no-repeat;
  background-size: 100%;
`

type OwnProps = PropsWithChildren<{
  showLogin?: boolean
}>

export const MainPage = ({ showLogin, children }: OwnProps): JSX.Element => (
  <Container
    size="sm"
    style={{
      padding: 0,
      minHeight: '100vh',
      backgroundImage: 'linear-gradient(0deg, #e8f8e7, #fff)'
    }}
  >
    <PageHeader showLogin={showLogin} />
    <Section>{children}</Section>
    <Footer />
    <Profile />
    <Gallery />
  </Container>
)
