import styled from '@emotion/styled'

export const LinkBtn = styled.button`
  appearance: none;
  border: none;
  display: inline;
  font-size: inherit;
  font-family: inherit;
  background: none;
  color: #0a6808;
  padding: 0;
  margin: 0;
  height: 1.5rem;
  font-weight: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
