import {
  closeFriendRequestModalEv,
  openProfileModalEv,
  openSelectCheckinModalEv
} from './../events/events'
import { createStore } from 'effector'
import { F, T, nthArg } from 'ramda'

import { saveProfileFx } from '../events/save-profile'
import { sendFriendRequestFx } from '../events/send-friendrequest'
import { logoutFx } from '../events/logout'

export const $profileModal = createStore(false)
  .on(openProfileModalEv, nthArg(1))
  .on(saveProfileFx.done, F)
  .on(logoutFx, F)

export const $requestFriendshipModalOpen = createStore(false)
  .on(sendFriendRequestFx, T)
  .on(closeFriendRequestModalEv, F)

export const $selectDogparkModal = createStore(false).on(openSelectCheckinModalEv, nthArg(1))
